import { blockContent } from './blockContent'

export const testimonials = `
_type == 'testimonials' => {
  ...,
  content[]{
    ${blockContent}
  },
  reviews[] {
    ...,
    'profile_photo_url': profile_photo_url.asset->url + '?auto=format&w=300'
  }
}
`
