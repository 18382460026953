export const stats = `
defined(stats) => {
  'backgroundColor': backgroundColor,
  'textColor': textColor,
  'statBgColor': statBgColor,
  'statTextColor': statTextColor,
  'title': title,
  'stats': stats
}
`
