import { blockContent } from './blockContent'
import { cta } from './cta'

export const cards = `
_type == 'cards' => {
  ...,
  defined(cardsSummary) => {
    cardsSummary[]{
      ...,
      content[]{
        ${blockContent}
      },
      defined(icon) => {
        'icon': icon.asset->url + '?auto=format',
      }
    },
  },
  defined(cardsWithImage) => {
    cardsWithImage[]{
      ...,
      content[]{
        ${blockContent}
      },
      defined(image) => {
        'image': image.asset->url + '?auto=format'
      },
      ${cta},
    },
  },

  defined(cardsShortArticle) => {
    cardsShortArticle[] {
      ...,
      content[]{
        ${blockContent}
      },
      ${cta}
    }
  }
}`
